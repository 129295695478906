<template>
  <HgAmadeusPackageTourSearch
    allowHotjarRecord
    :is-mobile="isMobile"
    :is-loading-destinations="$apollo.queries.autosuggestAmadeus.loading"
    :airports="allAirports"
    :searched-airports="searchAirports"
    :destinations="orderedDestinations"
    :ibe-tags="amadeusPackageTourCategories"
    :ibe-url="amadeusPackageTourSearchUrl"
    :roomLabel="$t('amadeusPackageTourSearch.roomLabel')"
    :infoTextSingleRoom="$t('amadeusPackageTourSearch.infoTextSingleRoom')"
    :infoTextSingles="$t('amadeusPackageTourSearch.infoTextSingles')"
    :infoTextSingleRoomMultiple="
      $t('amadeusPackageTourSearch.infoTextSingleRoomMultiple')
    "
    :infoTextMultipleSingular="
      $t('amadeusPackageTourSearch.infoTextMultipleSingular')
    "
    :infoTextMultiplePlural="
      $t('amadeusPackageTourSearch.infoTextMultiplePlural')
    "
    :showRooms="['de', 'at', 'ch'].includes($i18n.locale)"
    @onChangeAirportTerm="changeAirportTerm"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
import { mapState } from 'vuex';
import allAirportsQuery from '@/apollo/queries/airport/allAirports';
import autosuggestAmadeusQuery from '@/apollo/queries/destination/suggest';
import autosuggestDefaultAmadeusQuery from '@/apollo/queries/destination/defaultDestinations';
import amadeusPackageTourCategoriesQuery from '@/apollo/queries/ibe/amadeusPackageTourCategories';
import amadeusPackageTourSearchUrlQuery from '@/apollo/queries/ibe/amadeusPackageTourSearchUrl';
import { format } from 'date-fns';

import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  mixins: [GtmEventsMixin],

  data: () => ({
    destinationTerm: '',
    orderTypes: ['Destination', 'City', 'Region', 'Poi', 'Accommodation'],
    orderedDestinations: [],
    autosuggestAmadeus: [],
    allAirports: [],
    searchAirports: [],
    searchUrl: '',
    airports: [],
    destination: null,
    dateRange: null,
    duration: null,
    travelers: { adults: 2 },
    hasParams: false,
    roomInfoText: ['', '', '']
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  watch: {
    autosuggestAmadeus() {
      if (this.autosuggestAmadeus && this.autosuggestAmadeus.length > 0) {
        // Get relevant types
        const orderTypes = [];
        this.autosuggestAmadeus.forEach((elem) => {
          if (!orderTypes.includes(elem.type)) {
            orderTypes.push(elem.type);
          }
        });

        // Order results by types
        const ordered = [];

        orderTypes.forEach((element) => {
          ordered.push(
            this.autosuggestAmadeus.filter((elem) => {
              return elem.type === element;
            })
          );
        });

        this.orderedDestinations = [].concat(...ordered);
      }
    },
    autosuggestDefaultAmadeus() {
      if (
        this.autosuggestDefaultAmadeus?.length > 0 &&
        this.orderedDestinations.length === 0
      ) {
        this.orderedDestinations = this.autosuggestDefaultAmadeus;
      }
    }
  },

  mounted() {
    this.queryDefaultAirports();
    if (this.AmadeusPackageTourSearch && this.autosuggestDefaultAmadeus.length)
      this.orderedDestinations = this.autosuggestDefaultAmadeus;
  },

  methods: {
    updateDestinationTerm(term) {
      this.destinationTerm = term;
    },

    updateSearchParameters(params) {
      const {
        destination,
        airports,
        dateRange,
        duration,
        travelers,
        tags
      } = params;

      this.destination = destination;
      if (this.destination && this.destination.__typename) {
        delete this.destination.__typename;
        delete this.destination.villageName;
        delete this.destination.provinceName;
        delete this.destination.countryName;
        delete this.destination.destinationName;
        delete this.destination.default;
      }
      this.airports =
        (airports && airports.map((airport) => airport.iataCode)) || [];
      this.dateRange = dateRange;
      if (travelers) {
        this.travelers = travelers;
      }
      this.duration = duration || '';
      this.tags = (tags && tags.map((t) => t.id)) || [];
      this.hasParams = true;
    },

    triggerSearch() {
      let clickedElement = 'none';

      if (
        this.destination &&
        this.destination.name &&
        this.destination.default
      ) {
        clickedElement = 'dropdown';
      }
      if (
        this.destination &&
        this.destination.name &&
        !this.destination.default
      ) {
        clickedElement = 'autosuggest';
      }

      var duration;

      // Reisedauer für alle Varianten, außer beliebig, exakt, 1 Woche, 2 Wochen um je 1 erhöhen (NUR DE & AT)
      if (
        ['de', 'at'].includes(this.$i18n.locale) &&
        !['', 'exact', '7', '14'].includes(this.duration)
      ) {
        duration = this.duration
          .split(',')
          .map((el) => parseFloat(el) + 1)
          .join(',');
      } else {
        duration = this.duration;
      }

      this.$gtm.push({
        event: 'IBE_search_used',
        clickedElement,
        destination:
          this.destination && this.destination.name
            ? this.destination.name
            : 'any',
        period: `${format(this.dateRange.start, 'EE, dd.MM.yyyy')} - ${format(
          this.dateRange.end,
          'EE, dd.MM.yyyy'
        )}`,
        travelDuration: duration,
        departureAirport: this.airports,
        travellers: this.travelers
      });
    },

    changeAirportTerm(searchTerm) {
      this.queryAirports(searchTerm);
    },

    async queryDefaultAirports() {
      try {
        const {
          data: { allAirports }
        } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            filter: {
              onlyGrouped: true
            }
          }
        });
        this.allAirports = allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e.graphQLErrors);
      }
    },

    async queryAirports(searchTerm) {
      try {
        const { data } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            limit: 500,
            filter: {
              name: searchTerm,
              onlyGrouped: true
            }
          }
        });
        this.searchAirports = data.allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.warn('error', e.graphQLErrors);
      }
    }
  },

  apollo: {
    autosuggestAmadeus: {
      query: autosuggestAmadeusQuery,
      debounce: 300,
      skip() {
        return this.destinationTerm === '';
      },
      variables() {
        return {
          search: this.destinationTerm
        };
      }
    },
    amadeusPackageTourCategories: {
      query: amadeusPackageTourCategoriesQuery
    },
    amadeusPackageTourSearchUrl: {
      query: amadeusPackageTourSearchUrlQuery,
      skip() {
        return this.hasParams === false;
      },
      variables() {
        return {
          input: {
            destination: this.destination && {
              ...this.destination,
              type:
                this.destination.type == 'favoritesdefault'
                  ? 'Destination'
                  : this.destination.type
            },
            startDate: this.$dateFns.addDays(this.dateRange.start, 1),
            endDate: this.$dateFns.addDays(this.dateRange.end, 1),
            duration: this.duration,
            airports: this.airports,
            numberOfAdults: this.travelers.adults,
            agesOfChildren: this.travelers.ages,
            room: this.travelers.rooms,
            categories: this.tags
          }
        };
      }
    },
    autosuggestDefaultAmadeus: {
      query: autosuggestDefaultAmadeusQuery,
      variables() {
        return {
          type: 'PACKAGE_TOUR'
        };
      }
    }
  }
};
</script>
